import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Access } from '../model/access.model';
import { ModuleResolverService } from './module-resolver.service';

@Injectable({
  providedIn: 'root',
})
export class CproLazyService {

  private lazyMap: Map<string, Promise<unknown>> = new Map();

  constructor(
    private moduleResolverService: ModuleResolverService
  ) {
  }

  hasModule(key: string): boolean {
    return this.lazyMap.has(key);
  }

  getLazyModule(key: string): Promise<unknown> {
    return this.lazyMap.get(key);
  }

  loadLazyModule(impFn: any, href: string): Observable<number | void> {
    return of(1).pipe(
      tap(() => {
        this.lazyMap.set(
          href,
          impFn
        );
      })
    );
  }

  loadAndNavigate(item: Access, router: Router, activatedRoute?: ActivatedRoute, destination?: string): void {
    if (!this.hasModule(item.href)) {
      const impFn = this.moduleResolverService.resolveModule(item.componentType);
      this.loadLazyModule(impFn, item.href).subscribe(() => {
        const config = router.config;
        config.find(route => route.path === 'app').children.push({
          path: item.href,
          loadChildren: () => this.getLazyModule(item.href)
        });
        this.navigate(router, item, activatedRoute, destination);
      });
    } else {
      this.navigate(router, item, activatedRoute, destination);
    }
  }

  private navigate(router: Router, item: Access, activatedRoute?: ActivatedRoute, destination?: string): void {
    setTimeout(() => {
      if (activatedRoute) {
        router.navigate([destination ? destination : item.href], { relativeTo: activatedRoute, state: { access: item.access } });
      } else {
        router.navigateByUrl(`/app/${destination ? destination : item.href}`, { state: { access: item.access } });
      }
    });
  }

  public clearModules(): void {
    this.lazyMap = new Map<string, Promise<unknown>>();
  }
}
