import {Component, HostBinding, OnInit} from '@angular/core';
import { User } from '../../model/user.model';
import { CproAuthService } from '../../services/cpro-auth.service';

@Component({
  selector: 'cpro-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  @HostBinding('class')
  class = 'mat-typography';

  user: User;

  constructor(
    private authService: CproAuthService
  ) {
    authService.user$.subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

}
