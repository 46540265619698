import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'cpro-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  @HostBinding('style')
  style: SafeStyle;
  config: any;
  loginBackgroundStyle: SafeStyle;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', this.config.loginBackground);
    this.loginBackgroundStyle = {
      'background-image': `url('/cdn/${this.config.loginBackground}')`
    };
  }

  ngOnInit(): void {
  }

}
