<div class="cpro-parent-main-window">
  <div class="cpro-parent-menu">
    <cpro-user-info>
    </cpro-user-info>
    <cpro-menu-item
      *ngFor="let item of displayedMenuItems"
      [menuItem]="item"
      [$activatedSegment]="$activatedSegment"
      (click)="onMenuItemClick(item)">
    </cpro-menu-item>
  </div>
  <div class="cpro-parent-content">
    <mat-toolbar class="cpro-parent-toolbar">
      <div class="cpro-parent-logo-parent" *ngFor="let imgUrl of config.headerLogos">
        <img src="{{'/cdn/' + imgUrl}}" style="height: 100%; width: auto">
      </div>
      <span class="cpro-header-spacer"></span>
      <span class="cpro-main-container-title"></span>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar>
    <div class="cpro-parent-content-parent">
      <div style="width: 100%; height: 100%; overflow: visible">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>


