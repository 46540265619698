import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ModuleResolverService } from './services/module-resolver.service';
import { CproLazyService } from './services/cpro-lazy.service';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldSizeService } from './services/form-field-size.service';
import { AppInitService } from './app-init.service';

@Component({
  selector: 'cpro-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private initialized: boolean;

  constructor(
    @Inject(DOCUMENT) private doc,
    private moduleResolverService: ModuleResolverService,
    private activatedRoute: ActivatedRoute,
    private lazyService: CproLazyService,
    private renderer: Renderer2,
    private router: Router,
    private translate: TranslateService,
    private elementRef: ElementRef,
    private initService: AppInitService,
    private formFieldSizeService: FormFieldSizeService
  ) {
    const config = JSON.parse(sessionStorage.getItem('config'));
    this.formFieldSizeService.getFormFieldSizeObservable().subscribe(size => {
      this.renderer.setStyle(this.elementRef.nativeElement, '--form-field-size', `${size}em`, 2);
    });
    this.renderer.setStyle(this.elementRef.nativeElement, '--custom-primary-color', config.theming.primaryColor, 2);
    translate.setDefaultLang(config.i18n.defaultLang);
    const customStyle = this.renderer.createElement('custom-style');
    const style = this.renderer.createElement('style');
    this.renderer.setAttribute(style, 'include', 'material-color material-typography');
    this.renderer.appendChild(customStyle, style);
    this.doc.head.appendChild(customStyle);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd &&
        (event.url.startsWith('/app') || event.url.startsWith('/')) &&
        !event.url.startsWith('/login')) {
        this.initService.getAccess().subscribe(access => {
          sessionStorage.setItem('access', JSON.stringify(access));
          const url = event.url.split('/')[2] ? event.url.split('/')[2] : access[0].href;
          if (!this.lazyService.hasModule(url)) {
            const accessItem = access.find(item => item.href === url);
            if (accessItem) {
              this.lazyService.loadAndNavigate(
                accessItem,
                router,
                this.isHome(event) ? undefined : this.activatedRoute,
                this.isHome(event) ? undefined : event.url
              );
              this.initialized = true;
            }
          }
        });
      }
    });
  }

  private isHome(event: NavigationStart): boolean {
    return event.url === '/' || event.url === '/app';
  }
}
