import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormFieldSizeService {

  private formFieldSizeSubject = new ReplaySubject<number>(1);

  setFormFieldSize(size: number): void {
    this.formFieldSizeSubject.next(size);
  }

  getFormFieldSizeObservable(): Observable<number> {
    return this.formFieldSizeSubject.asObservable();
  }
}
