import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UserInfoComponent } from './parent/user-info/user-info.component';
import { ParentComponent } from './parent/parent.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MenuItemComponent } from './parent/menu-item/menu-item.component';
import { MatCardModule } from '@angular/material/card';
import { StoreModule } from '@ngrx/store';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { UsDateAdapter } from './helpers/date-adapter/us-date-adapter';
import { Platform } from '@angular/cdk/platform';
import { CHDateFormat } from './helpers/date-adapter/date-format';
import { CproNotificationModule, CproOverlayModule, CproPipesModule } from '@celsiuspro/cpro-core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { LoginStatusInterceptor } from './helpers/login-status.interceptor';
import { DatePipe } from '@angular/common';
import { DateHttpInterceptor } from './date-http-interceptor';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { AppInitService } from './app-init.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    UserInfoComponent,
    ParentComponent,
    MenuItemComponent,
    AuthComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    CproNotificationModule,
    StoreModule.forRoot({}),
    ReactiveFormsModule,
    CproOverlayModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatTableExporterModule,
    CproPipesModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatSelectModule
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CHDateFormat
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitFactory,
      deps: [HttpClient, AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginStatusInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'USD'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always'
      }
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(@Optional() @SkipSelf() appModule?: AppModule) {
    if (appModule) {
      throw new Error('AppModule already loaded!');
    }
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/cdn/translation/');
}

export function AppInitFactory(httpClient: HttpClient, appInitService: AppInitService): any {
  return () => appInitService.initApplication();
}
