<div class="cpro-auth-login-container">
  <div class="cpro-auth-login-background" [ngStyle]="loginBackgroundStyle">
    <cpro-login></cpro-login>
  </div>
  <div class="cpro-login-footer-area">
    <img class="cpro-footer-img"
         *ngFor="let logo of config.loginLogos"
         src="{{'/cdn/' + logo}}">
  </div>
</div>
