import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CproLazyService } from './cpro-lazy.service';
import { OverlayService } from '@celsiuspro/cpro-core';

@Injectable({
  providedIn: 'root'
})
export class CproAuthService {

  private userSubject: BehaviorSubject<User>;
  public user$: Observable<User>;

  constructor(
    private overlayService: OverlayService,
    private lazyService: CproLazyService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user$ = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(usernameoremail: string, password: string): Observable<User> {
    return this.httpClient.post<User>('/core-api/accounts/authenticate', {usernameoremail, password})
      .pipe(
        map(user => {
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  logout(): void {
    this.overlayService.overlayComponents$.next(undefined);
    this.lazyService.clearModules();
    this.httpClient.post('/core-api/accounts/revoke-token', {});
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['login']);
  }
}
