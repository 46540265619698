import { AfterViewInit, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Access } from '../../model/access.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'cpro-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit, AfterViewInit {

  @HostBinding('class')
  class = 'mat-typography';
  @HostBinding('style.background')
  background: string;
  @Input()
  menuItem: Access;
  @Input()
  $activatedSegment: Observable<string>;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.$activatedSegment.subscribe(segment => {
      if (this.menuItem.href === segment) {
        this.background = '#f1f1f1';
      } else {
        this.background = 'transparent';
      }
    });
  }

  ngAfterViewInit(): void {
  }

}
