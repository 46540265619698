import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Access } from '../model/access.model';
import { CproNotificationService } from '@celsiuspro/cpro-core';
import { CproLazyService } from '../services/cpro-lazy.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModuleResolverService } from '../services/module-resolver.service';
import { CproAuthService } from '../services/cpro-auth.service';

@Component({
  selector: 'cpro-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent implements OnInit {

  config: any;
  displayedMenuItems: Access[];
  $activatedSegment = new ReplaySubject<string>(1);
  sizes = [1, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35];

  constructor(
    private router: Router,
    private lazyService: CproLazyService,
    private notificationService: CproNotificationService,
    private moduleResolverService: ModuleResolverService,
    private authService: CproAuthService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.displayedMenuItems = JSON.parse(sessionStorage.getItem('access'));
        this.$activatedSegment.next(event.url.split('/')[2]);
      }
    });
  }

  ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
  }

  onMenuItemClick(item: Access): void {
    this.$activatedSegment.next(item.href);
    this.lazyService.loadAndNavigate(item, this.router, this.activatedRoute);
  }

  logout(): void {
    this.authService.logout();
  }
}
