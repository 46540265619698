<div class="cpro-auth-login-title-parent">
  <div class="cpro-auth-login-title mat-typography">
    {{config.applicationTitle}}
  </div>
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
  <div class="cpro-auth-login-parent">
    <mat-form-field>
      <mat-placeholder>Username or E-Mail</mat-placeholder>
      <input formControlName="usernameoremail" matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Password</mat-placeholder>
      <input [type]="hide ? 'password' : 'text'" formControlName="password" matInput>
      <mat-icon class="cpro-auth-show-password-icon" matSuffix
                (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
    <button mat-button type="submit"></button>
    <button
      class="cpro-auth-login-button"
      mat-raised-button
      color="primary">Login
    </button>
  </div>
</form>
