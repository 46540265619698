import { Injectable } from '@angular/core';
import { AccessType } from '../types/access.type';

@Injectable({
  providedIn: 'root'
})
export class ModuleResolverService {

  resolveModule(type: AccessType): Promise<any> {
    switch (type) {
      case AccessType.SEARCH_TERMS:
        return import('src/app/search-terms/search-terms.module').then(m => m.SearchTermsModule);
      case AccessType.TENDERS:
        return import('src/app/tenders/tenders.module').then(m => m.TendersModule);
      case AccessType.USER_MANAGEMENT:
        return import('src/app/user-management/user-management.module').then(m => m.UserManagementModule);
      default:
        return import('src/app/not-found/not-found.module').then(m => m.NotFoundModule);
    }
  }
}
