import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParentConfig } from './model/cpro-parent-config.model';
import { Access } from './model/access.model';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  initApplication(): Promise<any> {
    return new Promise<any>(((resolve, reject) => {
        this.getConfig().subscribe(config => {
        sessionStorage.setItem('config', JSON.stringify(config));
        resolve(true);
      }, () => {
        reject(true);
      });
    }));
  }

  getConfig(): Observable<ParentConfig> {
    return this.httpClient.get<ParentConfig>('/cdn/general-setup.json');
  }

  getAccess(): Observable<Access[]> {
    return this.httpClient.get<Access[]>('/core-api/accounts/get-my-access');
  }
}
