import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CproAuthService } from '../../services/cpro-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'cpro-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  loading = false;
  submitted = false;
  error = '';

  loginForm = this.formBuilder.group({
    usernameoremail: this.formBuilder.control('', Validators.required),
    password: this.formBuilder.control('', Validators.required)
  });
  title: string;
  config: any;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private authService: CproAuthService,
    private router: Router
  ) {
    if (this.authService.userValue) {
      this.router.navigate(['/']);
    }
    this.title = 'Tender Plattform';
  }

  ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
  }

  onSubmit(value: any): void {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(value.usernameoremail, value.password)
      .pipe(first())
      .subscribe({
        next: () => {
          const returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
          this.router.navigate([returnUrl]);
        },
        error: error => {
          this.error = error;
          this.loading = false;
        }
      });
  }

}
