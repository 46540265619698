import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CproAuthService } from '../services/cpro-auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: CproAuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.userValue;
    const isLoggedIn = currentUser && currentUser.jwtToken;
    const isApiUrl = req.url.includes('/core-api');
    const isCommonPath = req.url.includes('/common');
    if (isLoggedIn && (isApiUrl || isCommonPath)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.jwtToken}`
        }
      });
    }

    return next.handle(req);
  }

}
